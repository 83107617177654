
@tailwind base;
@tailwind components;
@tailwind utilities;

 @import '~firebaseui/dist/firebaseui.css';

 @import '~lightgallery/scss/lightgallery';

 

 :root {
    --ion-font-family: 'Raleway', sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Dark mode variables go here */
    color: unset;
    background-color: unset;
  }
}

:root {
  color-scheme: light only;
}

/* Fallback for older browsers or manual mode */
body.dark {
  /* Dark mode variables go here */
}


ion-content::part(scroll) {
  touch-action: pan-y pan-x pinch-zoom;
}


.heading {
    font-family: 'Orelega One', cursive;
}

.uncard {
  box-shadow: none !important;
  border-radius: 0% !important;
}

.link {
  color:inherit;
}

@media (max-width: 480px) {
  html {
      font-size: 13px;
  }
}

@media (min-width: 480px) {
  html {
      font-size: 15px;
  }
}

.fbig {
  font-size: 4.5rem;
}

.lh {
  line-height:  1.75em;
}

.img-cover {
  object-fit: cover;
}

.bg-primary {
  background-color: #0000004d;
}

.hotfix.header-md::after {
  /* Removes the toolbar shadow. */
  background: none;
}

.sticky-menu {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
  z-index: 999;
}

.sticky-bottom {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
  z-index: 999;
}


.subhead {
  color: var(--ion-color-primary);
  font-size : 2.25rem;
 text-transform: uppercase;
}

ion-card, ion-item{
  --background: transparent;
}


.simple-alert .alert-message{
  line-height: 1.5rem;
  font-size: 1rem;
}

.profile-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}






